<template>
    <div    >
        <p>{{ lang.company['indtast_dine_kontaktoplysninger'] }}</p>

        <div class="input_container">
            <form  class="contact100-form validate-form">
                <label for="name" class="namelbl">{{ lang.company['dit_fulde_navn'] }}</label>
                <div 
                    class="wrap-input100 validate-input"
                    :class="fullNameClassError"
                    ref="fullNameDiv"
                >
                    <input 
                        v-model="fullName" 
                        @blur="isValidFullName"
                        id="name" 
                        class="input100" 
                        type="text" 
                        name="name" 
                        autocomplete="name"
                        :placeholder="lang.company['placeholder_name']"
                    >
                    <span class="focus-input100 "></span>
                    <label class="label-input100" for="name">
                        <i class=" font_awsome_color fas fa-user-tie m-b-2" aria-hidden="true"></i>
                    </label>
                </div>


                <label for="email" class="namelbl">{{ lang.company['din_email_adresse'] }}</label>
                <span 
                    class="ui-icon-info ui-icon" 
                    style="display:inline-block;" 
                    :title="lang.company['email_bruges_ikke_tekst']">
                </span>

                <div 
                    class="wrap-input100 validate-input"
                    :class="emailClassError"
                    ref="emailDiv"
                >
                    <input 
                        v-model="email"
                        @blur="isValidEmail"
                        id="email"
                        class="input100"
                        type="text"
                        name="email"
                        autocomplete="email"
                        :placeholder="lang.company['placeholder_email']"
                    >
                    <span class="focus-input100"></span>
                    <label class="label-input100" for="email">
                        <i class=" font_awsome_color far fa-envelope m-b-2" aria-hidden="true"></i>
                    </label>
                </div>

                <label for="phone" class="namelbl">{{ lang.company['dit_mobilnummer'] }}</label>
                <span class="ui-icon-info ui-icon" style="display:inline-block;" :title="lang.company['mobil_bruges_ikke_tekst']"></span>

                <div
                    class="wrap-input100 tel-input"
                    :class="phoneClassError"
                    ref="phoneDiv"
                >
                    <vue-tel-input
                        @validate="phoneGetUpdated"
                        id="phone"
                        name="phone"
                        :defaultCountry='preferredCountries[0] ?? "dk" '
                        :autoDefaultCountry="false"
                        :inputOptions="{ required: true, placeholder: this.lang.company['placeholder_phone'] }"
                        :preferredCountries="preferredCountries"
                    />            
                </div>
            </form>
        </div>

        <br />
        <div class="policy_accept">
            
                <input type="checkbox" name="policy" id="policy" value="1" v-model="termsAndConditionsEnabled">
                <label ref="policyErrorDiv">
                    {{ lang.company['godkend_vilkår_for_onlinebooking'] }}
                   <a target="_new" :href="termsAndConditionsLink">{{ lang.company['vis_vilkår'] }}</a>
                </label>
            
        </div>
        <div 
            v-if="showPolicyError" 
            id="policyerror" 
            class="error"
        >
            <br>
            {{ lang.company['husk_at_godkende_vilkår'] }}
        </div>
        
        <div v-if="hasMarketingAndNewsLetterSignup" id="marketing" class="ui-buttonset">
            <br />

            {{ newsLetterText }} <br/>
            
                <MarketingCheckbox
                    v-if="restaurantHasNewsLetterEmail && restaurantHasMarketing"
                    text="email"
                    translatedText="email_marketing"
                    id="marketing_email_input"
                    name="marketing_email"
                    v-model="sendEmailEnabled"
                />

                <MarketingCheckbox

                    v-if="restaurantHasNewLetterSms && restaurantHasMarketing"
                    text="sms"
                    translatedText="sms"
                    id="marketing_sms_input"
                    name="marketing_sms"
                    v-model="sendSmsEnabled"
                    />
            
        </div>

        <div v-if="allowComment">
            <p>{{ lang.company['kommentar'] }}</p>
            <p v-html="getCommentText"/>

            <div class="input_container">
                <div class="wrap-input100 validate-input">
                    <textarea v-model="comment" id="comment" class="input100" name="message" :placeholder="lang.company['placeholder_comment']"></textarea>
                    <span class="focus-input100"></span>
                    <label class="label-input100 rs1" for="message">
                        <i class="far fa-comment"></i>
                    </label>
                </div>
            </div>

        </div>

        <br />
        <br />

        <div 
            v-if="error !== ''"
            class="error" 
            style="display: block;"
        >
            {{ error }}
            <br>
        </div>

        <span 
            class="button_confirmOptions ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" 
            role="button" 
            aria-disabled="false"
            @click="checkForm"
        >
            <span class="ui-button-text">{{ lang.company['gennemfoer_reservation'] }}</span>
        </span>

    </div>
</template>

<script lang="js">

import { VueTelInput } from 'vue-tel-input';
import CustomCheckboxButton from '../customButtons/CustomCheckboxButton.vue';
import MarketingCheckbox from '../customButtons/MarketingCheckbox.vue';

export default {
    name: 'ContactInformation',
    data() {
        return {
            fullName: '',
            fullNameClassError: '',

            email: '',
            emailClassError: '',

            phoneObject: '',
            phoneClassError: '',

            termsAndConditionsEnabled: false,
            showPolicyError: false,

            sendEmailEnabled: false,
            sendSmsEnabled: false,

            comment: '',
            commentClassError: '',            
        }
    },
    props: ['restaurantID', 'dataSelected', 'restaurantData', 'error'],
    components: {
        VueTelInput,
        CustomCheckboxButton,
        MarketingCheckbox,
},
    computed: {
        termsAndConditionsLink() {
            return `https://www.bord-booking.dk/booking_policy.php?id=${this.restaurantID}`
        },
        allowComment() {
            return this.restaurantData['allow_online_comments'] == 1
        },
        getCommentText(){
            const language = this.lang.name
            const commentJson = JSON.parse(this.restaurantData.texts['user_comment_text'])
            return commentJson[language]
        },
        hasMarketingAndNewsLetterSignup() {
            return this.restaurantData['beb_news_letter_sign_up'] == 1  || this.restaurantHasMarketing
        },
        restaurantHasMarketing() {
            return true // replace true with $restaurant->hasMarketing()
        },
        restaurantHasNewsLetterEmail() {
            return this.restaurantData['newsletter_email']
        },
        restaurantHasNewLetterSms() {
            return this.restaurantData['newsletter_sms']
        },
        lang() {
            return this.$store.state.lang
        },
        newsLetterText() {
            const language = this.lang.name
            const newsLetterSignUpTextJson = JSON.parse(this.restaurantData.texts['newsletter_sign_up_text'])
            const newsLetterText = newsLetterSignUpTextJson[language]
            let result = ""

            if (newsLetterText !== undefined) {
                result = newsLetterText
            }else if (this.restaurantData['beb_news_letter_sign_up'] === 1) {
                result = this.lang.company['oenskes_nyheder_og_tilbud_fra_restaurant_og_booketbord']
            }else if (this.restaurantData['beb_news_letter_sign_up'] === 0) {
                resutl = this.lang.company['oenskes_nyheder_og_tilbud'] 
            }else {
                result = this.lang.company['oenskes_nyheder_og_tilbud_fra_booketbord']
            }
            return result
        },
        preferredCountries() {
            return this.restaurantData.preferred_countries ?? ["dk", "no", "se"]
        }
    },
    methods: {
        checkForm(e) {
            e.preventDefault();

            const isValidForm = this.isValidateForm()
            if (!isValidForm) {
                console.log('Invalid form')                
            }else {
                this.dataSelected({
                    'name': this.fullName,
                    'email': this.email,
                    'mobile': this.phoneObject.number,
                    'sendEmail': this.sendEmailEnabled,
                    'sendSms': this.sendSmsEnabled,
                    'comment': this.comment
                })
            }
        },
        isValidFullName() {
            if (this.fullName.trim() == '') {
                this.fullNameClassError = 'alert-validate'
                return false
            }
            this.fullNameClassError = ''
            return true
        },
        isValidEmail() {
            const regex = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/
    
            if (this.email.trim().match(regex) == null) {
                this.emailClassError = 'alert-validate'
                return false
            }
            this.emailClassError = ''
            return true
        },
        phoneGetUpdated(phoneObject) {
            this.phoneObject = phoneObject
        },
        isValidNumber() {
            if (!this.phoneObject.valid || this.phoneObject.number == '' || typeof this.phoneObject.number === 'undefined' || this.phoneObject == false) {
                this.phoneClassError = 'alert-validate'
                return false
            }
            this.phoneClassError = ''
            return true
        },
        isValidateForm() {
            let elementToScrollTo = undefined

            const isValidFullName = this.isValidFullName()
            if (!isValidFullName) elementToScrollTo = this.$refs.fullNameDiv

            const isValidEmail = this.isValidEmail()
            if (!isValidEmail && !elementToScrollTo) elementToScrollTo = this.$refs.emailDiv 

            const isValidNumber = this.isValidNumber()
            if (!isValidNumber && !elementToScrollTo) elementToScrollTo = this.$refs.phoneDiv

            var isValidTermsAdConditions = true
            if (this.termsAndConditionsEnabled == false) {
                this.showPolicyError = true
                isValidTermsAdConditions = false

                if (!elementToScrollTo) 
                    elementToScrollTo = this.$refs.policyErrorDiv
            }else {
                this.showPolicyError = false
                isValidTermsAdConditions = true
            }

            if (elementToScrollTo) {
                this.$nextTick(() => {
                    this.scrollToElement(elementToScrollTo)
                })
            }

            return isValidFullName && isValidEmail && isValidNumber && isValidTermsAdConditions
        },
        scrollToElement(element) {
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        },
    }
}
</script>



<style src="vue-tel-input/dist/vue-tel-input.css"></style>