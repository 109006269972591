<template>
    <div class="container">
        <div id="margin">
            <div v-if="restaurantID == null">
                No restaurant id
            </div>
            <div v-else-if="error !== ''">
                {{ error }}
            </div>
            <div v-else>
                
                <div class="flags">
                    <a 
                        v-for="(langObj, i) in countryFlagsWithUrls" 
                        :key="`Lang${i}`"
                        :href="langObj.url"
                    >
                        <img    
                            :src="langObj.logo" 
                            :title="langObj.hoverText"
                            border="0"
                        >
                    </a>
                </div>

                <div v-if="cancelReservation">
                    <h1 class="test">{{ lang.company['slet_reservation_paa'] }} {{ restaurantData.company_name }}</h1>
                </div>
                <div v-else>
                    <img v-if="restaurantData && restaurantData.logo_url.length > 0" :src="restaurantData.logo_url" alt="Logo" class="logo logo_customer">
                    <h1 class="booking_at">{{ lang.company['du_er_ved_at_reservere_bord_hos:'] }}</h1>

                    <p class="address beb_header_text"> 
                        {{ restaurantData.company_name }} <br>
                        {{ restaurantData.company_adrress }} <br>
                        {{ restaurantData.company_post_code }} {{ restaurantData.company_city }}
                        <br>
                    </p>

                    <p v-if="restaurantOnlineInfo != ''" v-html="restaurantOnlineInfo" class="onlineinfo beb_header_text" ></p>

                    <p v-if="restaurantData.show_deleted_booking_link == 1" class="deletebooking beb_header_text">
                        {{ lang.company["vil_du_slette_booking_klik_her"] }} 
                        <a :href="cancelBookingLink" class="cancelBooking">
                            {{ lang.company['afbestil_reservation'] }}                            
                        </a>
                    </p>

                </div>


                <!-- {{ $route.params.id }} -->

                
                <div id="accordion" class="ui-accordion ui-widget ui-helper-reset" role="tablist" aria-disabled="false">
                    <Section 
                        v-for="(section, index) in newSectionsData"
                        :ref="section.id"
                        :key="index"
                        :title="section.title"
                        :isLocked="index > currentSectionIndex"
                        :isExpanded="currentSectionIndex == index"
                        :type="section.id"
                        :data="section.data"
                        :restaurantID="restaurantID"
                        :restaurantData="restaurantData"
                        :error="sectionError"
                        @onSectionClicked="toggleExpandCollapse(index)"
                        :onDataSelected="onDataSelected"
                    />
                </div>

                <div v-if="!isPageLoading">
                    <img 
                        src="https://bord-booking.dk/defaultRes/online_booking/images/booketbord.svg" 
                        alt="Logo" 
                        class="logo_booketbord"
                    >
                    <div style="clear:both;"></div>
                    <center>
                        <a style="color:gray; font-size:10px;" target="_blank" :href='`https://www.bord-booking.dk/booking_policy.php?id=`+restaurantID'>
                            {{ lang.company['vilkår_for_anvendelse'] }}
                        </a>
                    </center>
                </div>

                <div class="loading" v-if="isLoading || isPageLoading">
                    <img src="https://www.bord-booking.dk/defaultRes/online_booking/images/loading.gif">
                </div>
            </div>

            <AlertDialog
                :showModal="alertMessage !== ''"
                :message="alertMessage"
                @close="hideAlertDialog"
            />

        </div>

    </div>
</template>

<script lang="js">

import Section from '../components/Section.vue';
import SectionTypes from '../models/SectionTypesEnum.js';
import AlertDialog from '../components/templates/AlertDialog.vue';

import config from '../../config.js'
import ApiService from '../services/ApiService.js'

import defaultLanguages from '../../public/js/default_language.js'

export default {
    name: 'onlinebooking',
    data() {
        return {
            isPageLoading: true,
            isHoveringOnFlag: false,
            informationText: '',
            restaurantID: null,
            cancelReservation: false,
            apiService: NaN,
            restaurantData: false,
            error: '',
            sectionError: '',
            alertMessage: ''
        }
    },
    components: {
        Section,
        AlertDialog
    },
    computed: {
        isLoading() { return this.$store.state.isLoading },
        reservationMessage() { return this.$store.state.reservationMessage },
        newSectionsData() { return this.$store.state.newSectionsData },
        currentSectionIndex() { return this.$store.state.currentSectionIndex },
        countryFlagsWithUrls() { 
            if (this.restaurantData.languages == undefined) {
                return {}
            }
            const allLanguages = Object.keys(this.restaurantData.languages)

            var result = {}
            allLanguages.forEach((language, index) => {
                const params = { ...this.$route.query }
                params['lang'] = language
                const url = this.createUrl(params)

                result[index] = {
                    'lang': language,
                    'logo': `https://www.bord-booking.dk/defaultRes/gfx/${language}.png`,
                    'hoverText': this.lang.company[`skift_sprog_til_${language}`]??'',
                    'url': url    
                }
            })
            return result
        },
        restaurantOnlineInfo() {
            if (this.restaurantData.texts == undefined || this.restaurantData.texts.online_info == undefined) {
                return ''
            }
            const obj = JSON.parse(this.restaurantData.texts.online_info)
            const currentLang = this.lang.name
            return obj[currentLang] || ''
        },
        restaurantLogo() { 
            return (this.restaurantID == null) ? "" : `https://www.bord-booking.dk/site/upload/restaurant_images/x312/${this.restaurantID}-LOGO.png`
        },
        termsAndConditions() {
            return `https://www.bord-booking.dk/booking_policy.php?id=${this.restaurantID}`
        },
        cancelBookingLink() {
            let params = {
//                'restaurantId': this.restaurantID,
                'delete': 1,
            }
            if (this.$route.query.hasOwnProperty('lang')) {
                params['lang'] = { ...this.$route.query }.lang
            }
            if (this.$route.query.hasOwnProperty('restaurantId')) {
                params['restaurantId'] = { ...this.$route.query }.restaurantId
            }
            return this.createUrl(params)
        },
        lang() {
            return this.$store.state.lang
        },
    },
    methods: {
        createUrl(params) {
            const baseURL = window.location.href.split('?')[0]
            let paramsStr = ""

            let paramsKeys = Object.keys(params)
            if (paramsKeys.length != 0) {
                paramsStr += "?"
                paramsKeys.forEach((key, index) => {
                    paramsStr += `${key}=${params[key]}`
                    if (index < paramsKeys.length - 1) {
                        paramsStr += "&"
                    }
                })
            }
            return baseURL + paramsStr
        },
        countryFlag(lang) {
            return (lang == '') ? '' : `https://www.bord-booking.dk/defaultRes/gfx/${lang}.png`
        },
        changeLanguage(lang) {
            const query = {
                'restaurantId': this.restaurantID,
                'lang': lang
            }

            if (lang === this.$i18n.locale) {
                if (!this.$route.query.hasOwnProperty('lang')) {
                    this.$router.push({query: query})
                }else{
                    this.$router.go()
                }
                return
            }else {
                const hasDeleteParam = this.$route.query.hasOwnProperty('delete')
                if (hasDeleteParam) {
                    query["delete"] = this.$route.query['delete']
                }
                this.$router.push({ path: '/onlinebooking', query: query })
            }

        },
        toggleExpandCollapse(index) {
            const currentSectionIndex = this.$store.state.currentSectionIndex
            if (index < currentSectionIndex) {
                var currentSectionType = this.$store.getters.getSectionTypeAtIndex(currentSectionIndex)
                this.scrollToSection(currentSectionType)
            }
            this.$store.commit('changedSelectedIndex', {
                index: index,
                apiService: this.apiService
            })
            this.$store.commit('updateSections', {})
            this.sectionError = ''
        },
        onDataSelected(sectionType, data) {
            this.$store.commit('setDataToSection', {sectionID: sectionType, data: data})

            this.$store.dispatch('dataValidator', this.apiService)
                .then((result) => {
                    let isValid = result.isValid
                    if (isValid) {
                        if (!this.$store.getters.isLastSection) {
                            this.moveToNextSection(sectionType)
                        }
                    }else {
                        let error = result.error
                        this.sectionError = error
                    }
                })
        },
        moveToNextSection(sectionType) {
            trackPageView(sectionType);
            this.$store.commit('incremenetCurrentSectionIndex')
            this.$store.commit('updateSections')
            let currentIndex = this.$store.state.currentSectionIndex
            var nextSectionType = this.$store.getters.getSectionTypeAtIndex(currentIndex)

            this.scrollToSection(nextSectionType)
            this.$store.dispatch(`request-${nextSectionType}`, this.apiService)
                .then(() => {
                    let section = this.$store.state.newSectionsData.find(section=>section.id===nextSectionType)
                    if (section.id == SectionTypes.BOOKING_COMPLETE){
                        trackCompleted(section.data.bookingID, section.data.persons)
                    }
                    let isSpecialBookingOptionsSection = nextSectionType == SectionTypes.SELECT_SPECIAL_BOOKING_OPTIONS
                    let isConfirmAreaSection = nextSectionType == SectionTypes.CONFIRM_AREA

                    if (isSpecialBookingOptionsSection || isConfirmAreaSection) {
                        const isEmptyContent = section.data.hasOwnProperty('noContent')
                        if (isEmptyContent) {
                            this.onDataSelected(nextSectionType, section.data)
                        }
                    }
                })
        },
        scrollToSection(sectionType) {
            this.$nextTick(() => {
                const element = this.$refs[sectionType][0].$el
                window.scrollTo({
                    top: element.offsetTop,
                    behavior: 'smooth'
                });
            });
        },
        async loadRestaurantData() {
            try {
                //const filePath = `/cache/companies/${this.restaurantID}.json`
                let filePath = `recache.php?id=${this.restaurantID}`
                filePath = process.env.VUE_APP_RECACHE_URL + `?id=${this.restaurantID}`
                const response = await fetch(filePath)
                const data = await response.json()
                this.restaurantData = data
            }catch (error) {
                this.restaurantData = {}
                this.error = 'Unknown Company'
                this.isPageLoading = false
            } 

            this.$store.state.cancelReservation = this.cancelReservation
            this.$store.state.showConceptSection = Math.max(Number(this.restaurantData.show_concepts),Number(this.restaurantData.show_events))
            this.$store.state.showAreaSection = Number(this.restaurantData.allow_area_in_online_booking)
            this.$store.state.showNumberOfChildrenSection = this.restaurantData.show_children == "show"
            this.$store.state.showNumberOfChildrenChairSection = this.restaurantData.show_child_Chairs == "show"

            this.$store.commit('setMainSections')
            this.$store.commit('updateSections')
        },
        async loadLang({params}) {
            var lang = ''
            if (params.hasOwnProperty('lang')) {
                lang = params.lang
            } else {
                const fallbackLang = this.$i18n.locale
                const restaurantLanguages = Object.keys(this.restaurantData['languages'])

                if (restaurantLanguages.indexOf(fallbackLang) > -1) {
                    lang = fallbackLang
                } else if (restaurantLanguages.length > 0) {
                    lang = restaurantLanguages[0]
                } else {
                    console.error('Failed to find language')
                }
            }

            let companyLanguage = {}
            if (this.restaurantData.languages !== undefined) {
                companyLanguage = this.restaurantData.languages[lang]
            }

            var defaultLanguage = {}

            if (lang == 'en-uk') {
                defaultLanguage = defaultLanguages['en-gb']
            }else {
                defaultLanguage = defaultLanguages[lang]
            }

            const newLang = {
                name: lang,
                default: defaultLanguage,
                company: companyLanguage
            }
            this.$store.state.lang = newLang
            this.$store.commit('updateLang', {lang: newLang})
        },
        setPageTitle() {
            let subTitle1 = this.lang.company['reserver_bord_paa']
            let subTitle2 = this.restaurantData['company_name']
            document.title = subTitle1 + subTitle2
        },
        async requestFirstSectionData() {
            let currentIndex = this.$store.state.currentSectionIndex
            let sectionType = this.$store.getters.getSectionTypeAtIndex(currentIndex)
            this.$store.dispatch(`request-${sectionType}`, this.apiService)
        },
        async loadCSS() {
            var style = document.createElement('style');
            style.setAttribute('type', 'text/css');
            style.innerHTML = this.restaurantData.style_sheet
            document.getElementsByTagName('body')[0].appendChild(style)
        },
        showAlertDialog(message) {
            this.alertMessage = message
        },
        hideAlertDialog() {
            this.alertMessage = ''
        },
        loadTrackingScripts() {
            if (this.restaurantData) {
                // Load Google Analytics if tracking ID is available
                if (this.restaurantData.tracking_ga4) {
                    this.loadGoogleAnalytics(this.restaurantData.tracking_ga4);
                }

        // Load Facebook Pixel if tracking ID is available
                if (this.restaurantData.tracking_fbpixel) {
                    this.loadFacebookPixel(this.restaurantData.tracking_fbpixel);
                }
            }
         },
    
    loadGoogleAnalytics(ga4ID) {
      const gaScript = document.createElement('script');
      gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${ga4ID}`;
      gaScript.async = true;
      document.head.appendChild(gaScript);

      gaScript.onload = () => {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () { 
            window.dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', ga4ID);
      };
    },
    
    loadFacebookPixel(fbpixelID) {
      (function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

      fbq('init', fbpixelID);
      fbq('track', 'PageView');
    }
    
    },
    mounted() {
        this.$root.$on('show-date-warning-modal', this.showAlertDialog)

    },
    async created() {
      /*  if (!this.$route.query.hasOwnProperty('restaurantId') || isNaN(this.$route.query.restaurantId)) {
            console.log("Was not able to find restaurant id in querystring",this.$route.query)
            return
        }*/
        function stripStringAfterColon(str) {
            //chat-gpt
            const index = str.indexOf(":");
            if (index !== -1) {
                return str.slice(0, index);
            }
            return str;
        }
        
        const params = this.$route.query
        if(this.$route.query.hasOwnProperty('restaurantId')){
            if(parseInt(this.$route.query.restaurantId) > 0){
                this.restaurantID = parseInt(this.$route.query.restaurantId)
            } else {
                // probably testsite
                if(window.location.host != 'book.booketbord.dk' && window.location.host != 'dev.booketbord.dk'  && stripStringAfterColon(window.location.host) != 'localhost'){
                    console.log("Wrong domain",this.$route.query)
                    return
                } else {
                    // we allow testsite to enter domain directly as restaurantId
                    this.restaurantID = this.$route.query.restaurantId.trim()
                }
            }
        } else {
            // on live sites..
            this.restaurantID = stripStringAfterColon(window.location.host);
        }

        this.apiService = new ApiService(config, this.restaurantID)

        if (params.hasOwnProperty('delete')) {
            this.cancelReservation = params.delete == 1
        }

        this.isPageLoading = true
        await this.loadRestaurantData()
        this.restaurantID = this.restaurantData.company_id
        this.apiService.setRestaurantID(this.restaurantData.company_id)
        await this.loadLang({params})
        this.setPageTitle()
        await this.requestFirstSectionData()
        await this.loadCSS()
        this.isPageLoading = false
        this.loadTrackingScripts()
    }
}


        function trackPageView(cmd) {
            try{
                ga4PageView(cmd);
                gtagPageView(cmd);
                fbPageview(cmd);
            } catch(e){
                console.log("Exception: ",e);
            }
		}
		function trackCompleted(BookingIdentifierNo,persons){
            try{
                ga4TrackCompleted(BookingIdentifierNo,persons);
                // gtagTrackCompleted(BookingIdentifierNo,persons);
                fbTrackCompleted(BookingIdentifierNo,persons);
            } catch(e){
                console.log("Exception: ",e);
            }
		}
		function fbTrackCompleted(BookingIdentifierNo,persons){
			if (typeof fbq === 'undefined') {
				// we dont have fb loaded
			} else {
				fbq('trackCustom', 'booking_completed',{
					'persons':persons,
					'booking_no':BookingIdentifierNo
				});
			}			
		}
		function ga4TrackCompleted(BookingIdentifierNo,persons){
			if (window.gtag) {
				gtag("event", "booking_completed", {
					'event_category': 'bookings',
					'persons': persons,
					'booking_no': BookingIdentifierNo,
				});
			}
		}

		function ga4PageView(cmd) {
            console.log('called ga4PageView',window.gtag);
			if (window.gtag) {
                console.log('called ga4PageView/2');
				gtag('event', 'page_view', {
					page_title: cmd,
					page_location: '/online_booking/' + cmd
				}
				);
			}
		}

		function gtagPageView(cmd) {
            console.log('called gtagPageView');
			if (window.gtag && dataLayer[1][1]) {
                console.log('called gtagPageView/2');
				gtag('config', dataLayer[1][1], {
					'page_path': '/online_booking/' + cmd
				});
			}
		}

		function fbPageview(cmd) {
			if (typeof fbq === 'undefined') {
				// we dont have fb loaded
			} else {
				fbq('trackCustom', '/online_booking/' + cmd);
			}
		}
</script>


<style src="../assets/online_booking.css"></style>
<style src="../assets/all.min.css"></style>
<style src="../assets/base.css"></style>
<style src="../assets/icons.css"></style>
<style src="../assets/interaction.css"></style>

<style>
.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, .5);
}
</style>